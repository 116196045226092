import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthQuery } from '@auth/state/auth.query';
import { AuthService } from '@auth/state/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  isLoggedIn$ = this.authQuery.isLoggedIn$;
  name$ = this.authQuery.selectName$;

  constructor(private authQuery: AuthQuery, private authSvc: AuthService, private router: Router) { }


  logOut() {
    this.authSvc.logout();
    this.router.navigateByUrl('/auth/login');
  }

}
