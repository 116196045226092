import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { AuthStore, AuthState } from './auth.store';


@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {

  isLoggedIn$ = this.select(u => !!u.token);
  selectToken$ = this.select('token');
  selectName$ = this.select(u => u.firstName + ' ' + u.lastName);

  constructor(protected store: AuthStore) {
    super(store);
  }

  getToken() {
    return this.getValue().token;
  }

}
