<header *ngIf="(isLoggedIn$ | async)" class="bg-red-400 text-white">
  <div class="container mx-auto">
    <div class="flex justify-between items-baseline py-2">
      <span class="flex items-baseline">
        <a class="text-3xl hover:text-yellow-200" routerLink="/">Entrada</a>
        <!-- <a class="ml-6 text-base hover:text-yellow-200" routerLink="/products" routerLinkActive="text-yellow-200">Products</a> -->
        <!-- <a class="ml-3 text-base hover:text-yellow-200" routerLink="/users" routerLinkActive="text-yellow-200">Users</a> -->
      </span>
      <span class="text-base">
        Welcome, {{ name$ | async }}
        <a class="ml-2 text-yellow-300 hover:text-yellow-200" (click)="logOut()">Logout</a>
      </span>
    </div>
  </div>
</header>
