import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPage } from '@auth/pages/login/login.page';
import { RegisterPage } from '@auth/pages/register/register.page';
import { AuthGuard } from '@auth/auth.guard';


const routes: Routes = [
  {
    path: 'auth',
    children: [
      { path: 'login', component: LoginPage },
      { path: 'register', component: RegisterPage },

      { path: '', pathMatch: 'full', redirectTo: 'login' },
    ]
  },

  {
    path: 'texts',
    canActivate: [AuthGuard],
    loadChildren: () => import('../texts/texts.module').then(m => m.TextsModule)
  },

  {
    path: 'phoneNumbers',
    canActivate: [AuthGuard],
    loadChildren: () => import('../phoneNumbers/phoneNumbers.module').then(m => m.PhoneNumbersModule)
  },


  { path: '**', redirectTo: 'texts' },
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
