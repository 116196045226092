import { Component } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@auth/state/auth.service';


@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  isWrongCredentials = false;

  form = this.fb.group({
           username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
           password: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
         }, { updateOn: 'blur' });


  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) { }


  doLogin(frm: FormGroupDirective) {
    if (this.form.invalid)
      return;

    /*
    // TODO: do login
    this.form.reset();
    this.form.markAsPristine();
    frm.resetForm();
    */
    this.authService
        .login(this.form.value)
        .subscribe(
          success => { this.router.navigateByUrl('/texts'); },
          err => {
            this.isWrongCredentials = true;
          }
        );
  }

}
