import { Component } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@auth/state/auth.service';

@Component({
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss']
})
export class RegisterPage {

  form = this.fb.group({
    username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
    firstName: ['', Validators.compose([Validators.required])],
    lastName: [''],
    password: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
    // confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
  }, { updateOn: 'blur' });

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) { }


  doRegister(frm: FormGroupDirective) {
    if (this.form.invalid)
    return;

    this.authService
        .register(this.form.value)
        .subscribe(
          success => { 
            this.router.navigateByUrl('/login'); 
            this.form.reset();
            this.form.markAsPristine();
            frm.resetForm();
          },
          err => console.log(err)
        );
  }

}
