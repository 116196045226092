import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';


export interface AuthState {
   token: string;
   username: string;
   firstName: string;
   lastName: string;
}


export function createInitialState(): AuthState {
  return {
    token: '',
    username: '',
    firstName: '',
    lastName: ''
  };
}


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', producerFn: produce, resettable: true })
export class AuthStore extends Store<AuthState> {

  constructor() {
    super(createInitialState());
  }

}
