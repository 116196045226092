<div class="flex flex-col justify-center items-center h-full">

  <form [formGroup]="form" #frm="ngForm" (submit)="doLogin(frm)" class="flex flex-col w-96 border-gray-300 border-2">

    <div class="bg-gray-200 border-gray-300 border-b-2 text-lg font-bold flex justify-center py-2">
      Login
    </div>
    <div class="flex flex-col p-5">
      <div class="m-2 flex justify-between">
        <label for="username" class="font-bold">Username</label>
        <input type="text" id="username" formControlName="username" class="border-2 border-gray">
      </div>
      <div class="mb-2 ml-2 text-red-500 text-sm" *ngIf="(form.controls.username.dirty || frm.submitted) && form.controls.username.invalid">
        <span *ngIf="form.controls.username.errors?.required">Username is required</span>
        <span *ngIf="form.controls.username.errors?.minlength">Username must be minimum 3 characters</span>
      </div>

      <div class="m-2 flex justify-between">
        <label for="password" class="font-bold">Password</label>
        <input type="password" id="password" formControlName="password" class="border-2 border-gray">
      </div>
      <div class="mb-2 ml-2 text-red-500 text-sm" *ngIf="(form.controls.password.dirty || frm.submitted) && form.controls.password.invalid">
        <span *ngIf="form.controls.password.errors?.required">Password is required</span>
        <span *ngIf="form.controls.password.errors?.minlength">Password must be minimum 3 characters</span>
        <span *ngIf="isWrongCredentials">Invalid credentials</span>
      </div>

      <div class="m-2">
        New User? <a routerLink="../register" class="text-blue-500 underline">Register</a> now.
      </div>
    </div>
    <div class="justify-center mb-2 ml-2 text-red-500 text-sm" *ngIf="isWrongCredentials">
      <span>Invalid credentials</span>
    </div>
    <div class="bg-gray-200 flex justify-center py-2">
      <button type="submit" class="bg-red-500 text-white px-3 py-1">Login</button>
    </div>
  </form>
</div>
