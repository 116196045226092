import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthQuery } from './state/auth.query';
import { AuthService } from './state/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authQuery: AuthQuery, private authSvc: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!!this.authQuery.getToken())
      req = req.clone({ setHeaders: { Authorization: `Bearer ${this.authQuery.getToken()}` } });

    return next.handle(req)
               .pipe(catchError((err: HttpErrorResponse) => {
                 if (err.status === 401) {
                   this.authSvc.logout();
                   this.router.navigateByUrl('/auth/login');
                 }

                 return throwError(err);
               }));
  }
}
