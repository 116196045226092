import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthStore } from './auth.store';
import { RegisterParams, RegisterResult } from '../models/register';
import { LoginParams, LoginResult } from '../models/login';
import { persistState } from '@datorama/akita';


@Injectable({ providedIn: 'root' })
export class AuthService {

  storage: { clearStore(storeName?: string): void; } = { clearStore: () => {} };

  constructor(private store: AuthStore, private http: HttpClient) { }


  register(params: RegisterParams) {
    return this.http
               .post<RegisterResult>(environment.baseUrl + '/users/register', params);
  }

  login(params: LoginParams) {
    return this.http
               .post<LoginResult>(environment.baseUrl + '/auth/token', params)
               .pipe(tap(result => {
                 this.store.update(state => {
                   state.token = result.token;
                   state.username = result.username;
                   state.firstName = result.firstName;
                   state.lastName = result.lastName;
                 });

                 this.storage = persistState({ include: [this.store.storeName] });
               }));
  }

  logout() {
    this.store.reset();
    this.storage.clearStore(this.store.storeName);
  }

}
