import { Component } from '@angular/core';

import { AuthQuery } from '@auth/state/auth.query';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  isLoggedIn$ = this.authQuery.isLoggedIn$;

  constructor(private authQuery: AuthQuery) { }

}
